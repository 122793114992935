/*----------------------------------------------------------------------------*\
    ALERT
    JS for alert messages
\*----------------------------------------------------------------------------*/

window.ggd.alert = {
    init: function(){
        var self = this;
        var $elements = document.querySelectorAll('.js--alert');

        for (var i = 0; i < $elements.length; ++i) {
            var $element = $elements[i];
            self.initAlert($element);
        }
    },

    initAlert: function ($element) {
        var self = this;
        var $alertType = $element.getAttribute('data-alert');

        var cookielaw = window.ggd.cookie.readCookie($alertType);

        // Check if alert is already closed is in a cookie
        if (cookielaw !== $alertType) {
            $element.classList.add('is-visible');

            self.calcHeight($element, $alertType);
            document.querySelector('footer').style.marginBottom = $element.offsetHeight;
        }
    },

    calcHeight: function ($element, $alertType) {
        var self = this;

        $element.style.height = 'auto';
        var $alertHeight = $element.offsetHeight;

        $element.style.height = $alertHeight + 'px';

        self.closeAlert($element, $alertType);
    },

    closeAlert: function ($element, $alertType) {
        var $closeTriggers = $element.querySelectorAll('.js--alert-close');

        for (var i = 0; i < $closeTriggers.length; ++i) {
            var $closeTrigger = $closeTriggers[i];

            // Click on close button, close alert.
            $closeTrigger.addEventListener('click', function() {
                $element.hidden = true;
                // Check if alert is cookiebar, then set cookie for 1 year. If alert is not cookiebar then create session cookie.
                if (!this.classList.contains('js--alert-cookielaw')) {
                    // Set 1 year cookie
                    window.ggd.cookie.createCookie($alertType, $alertType, '');
                } else {
                    // Set Session cookie
                    window.ggd.cookie.createCookie($alertType, $alertType, 365);
                }
            });
        }
    }
};


window.addEventListener('load', function () {
    window.ggd.alert.init();
}, false);

window.addEventListener('resize', function () {
    window.ggd.alert.init();
}, false);
