/*----------------------------------------------------------------------------*\
    Config
    All global settings for JS.
\*----------------------------------------------------------------------------*/

window.ggd = {};

window.ggd.config = {
    mq: {
        'palm': '(min-width: 0)',
        'palm-large': '(min-width: 639px)',
        'lap': '(min-width: 640px)',
        'desk': '(min-width: 1024px)'
    }
};
