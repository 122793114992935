/*----------------------------------------------------------------------------*\
    Accordeon
    Simple, accessible accordeon
\*----------------------------------------------------------------------------*/

window.ggd.accordeon = {
    init: function(){
        this.initAccordeons();
    },

    initAccordeons: function () {
        var self = this;
        var $elements = document.querySelectorAll('.js--accordeon:not(.is-initialised)');

        for (var i = 0; i < $elements.length; ++i) {
            var $element = $elements[i];
            self.setupAccordeon($element);
        }
    },

    setupAccordeon: function ($accordeon) {
        var self = this;
        if(!$accordeon.classList.contains('is-initialised')) {
            self.setupToggles($accordeon);
            $accordeon.classList.add('is-initialised');
        }
    },

    setupToggles: function ($accordeon) {
        var self = this;
        var $toggles = $accordeon.querySelectorAll('.js--accordeon__toggle');

        for (var i = 0; i < $toggles.length; ++i) {
            var $toggle = $toggles[i];
            self.initToggle($accordeon, $toggle);
        }
    },

    initToggle: function ($accordeon, $toggle) {
        var $item = $toggle.closest('.js--accordeon__item');

        if ($item.classList.contains('is-open')) {
            $toggle.setAttribute('aria-expanded', 'true');
        } else {
            $toggle.setAttribute('aria-expanded', 'false');
        }



        $toggle.addEventListener('click', function () {
            if ($item.classList.contains('is-open')) {
                $item.classList.remove('is-open');
                $toggle.setAttribute('aria-expanded', 'false');
            } else {
                $item.classList.add('is-open');
                $toggle.setAttribute('aria-expanded', 'true');
            }
        });
    }
};


window.addEventListener('load', function () {
    window.ggd.accordeon.init();
}, false);
