/*----------------------------------------------------------------------------*\
    Forms
    All JS form form elements
\*----------------------------------------------------------------------------*/

// import Pikaday from 'pikaday';

window.ggd.forms = {
    init: function(){
        this.inputFile();
    },

    inputFile: function () {
        var $elements = document.querySelector('.js--input-file');

        if (!$elements) {
            return;
        }

        // Get input-file name
        $elements.querySelector('.js--input-file-mask').onchange = function () {
            $elements.querySelector('.js--input-file-text').value = this.files[0].name;
        };
    },
};


window.addEventListener('load', function () {
    window.ggd.forms.init();
}, false);
