/*----------------------------------------------------------------------------*\
    Maps
    Google Maps functions
\*----------------------------------------------------------------------------*/

window.ggd.maps = {
    init: function(){
        var self = this;
        var $mapContainers = document.querySelectorAll('.js--map');

        for (var i = 0; i < $mapContainers.length; ++i) {
            var $mapContainer = $mapContainers[i];
            self.initMaps($mapContainer);
        }
    },

    initMaps: function ($mapContainer) {
        var self = this,
            $map,
            $infoWindow = new window.google.maps.InfoWindow(),
            mapZoom = parseFloat($mapContainer.getAttribute('data-map-zoom')),
            mapLat = parseFloat($mapContainer.getAttribute('data-map-lat')),
            mapLong = parseFloat($mapContainer.getAttribute('data-map-long')),
            scrollToZoom = $mapContainer.getAttribute('data-map-scroll-to-zoom');

        if(typeof scrollToZoom === 'undefined' || scrollToZoom === null || scrollToZoom === '' ){
            scrollToZoom = 'true';
        }

        // Create Google Maps instance.
        $map = new window.google.maps.Map($mapContainer, {
            center: {
                lat: mapLat,
                lng: mapLong
            },
            zoom: mapZoom,
            scrollwheel: 'true' === scrollToZoom
        });

        // Get JSON file with Maps info.
        self.getJson($map, $infoWindow, $mapContainer);
    },

    // Add markers to Google maps.
    addMarkers: function ($map, $infoWindow, dataValues) {
        var self = this;
        var marker;

        var icon = {
            url: '/static/public/images/locationpin.png'
        };

        // Set longitude and latitude from JSON array.
        var longitude = parseFloat(dataValues.Longitude);
        var latitude = parseFloat(dataValues.Latitude);

        var location = {
            lat: latitude,
            lng: longitude
        };

        marker = new window.google.maps.Marker({
            position: location,
            map: $map,
            icon: icon
        });

        self.createInfoWindow($map, $infoWindow, marker, dataValues);

        // CURRENTLY NOT USING THIS FUNCTION, MAYBE IN A LATER STADIUM TO CLUSTER MARKERS.
        //self.setZoom($map, longitude, latitude, $boundBox);
    },

    // CURRENTLY NOT USING THIS FUNCTION, MAYBE IN A LATER STADIUM TO CLUSTER MARKERS.
    // Set the zoom to fit comfortably all the markers in the map
    /*setZoom: function (map, longitude, latitude, $boundBox) {
        $boundBox.extend(new google.maps.LatLng(latitude, longitude));

        // Don't zoom in too far on only one marker
        if ($boundBox.getNorthEast().equals($boundBox.getSouthWest())) {
            var extendPoint1 = new google.maps.LatLng($boundBox.getNorthEast().lat() + 0.01, $boundBox.getNorthEast().lng() + 0.01);
            var extendPoint2 = new google.maps.LatLng($boundBox.getNorthEast().lat() - 0.01, $boundBox.getNorthEast().lng() - 0.01);
            $boundBox.extend(extendPoint1);
            $boundBox.extend(extendPoint2);
        }

        map.setCenter($boundBox.getCenter());
        map.fitBounds($boundBox);
    },*/


    // Create info window when clicking on marker.
    createInfoWindow: function ($map, $infoWindow, marker, dataValues) {
        var contentString = '<div class="map-info  box  box--small">'+
            '<h2 class="h4">'+ dataValues.City +'</h2>' +
            '<ul class="list-clean">'+
            '<li>'+ dataValues.Street + dataValues.Housenumber +'</li>' +
            '<li>'+ dataValues.Zipcode +'</li>' +
            '<li><a href="tel:' + dataValues.Phonenumber + '">' + dataValues.Phonenumber + '</a></li>' +
            '<li><a href="' + dataValues.Link + '">Pagina</a></li>' +
            '</ul>' +
            '<a class="button" href="'+ dataValues.Route +'">' +
            '<span class="button__inner">' +
            '<span class="button__label">' +
            'Routebeschrijving' +
            '</span>' +
            '<span class="button__wrap-icon">' +
            '<i class="icon icon--arrow__right" role="presentation"><span class="visuallyhidden">Bekijk regio</span></i>' +
            '</span>' +
            '</span>' +
            '</a>' +
            '</div>';

        marker.addListener('click', function() {
            $infoWindow.setContent(contentString);
            $infoWindow.open($map, marker);
        });
    },

    // Get JSON with Maps info
    getJson: function ($map, $infoWindow, $mapContainer) {
        var self = this;
        var request = new XMLHttpRequest();
        var dataURL = $mapContainer.getAttribute('data-map-url');
        var singleJson = $mapContainer.getAttribute('data-single-clinic');

        if(typeof dataURL !== 'undefined' && dataURL !== null && dataURL !== '') {
            request.open('GET', dataURL, true);

            request.onload = function() {
                if (request.status >= 200 && request.status < 400) {
                    // XML load succesfull
                    var data = JSON.parse(request.responseText);

                    // Loop trough all JSON data
                    if (typeof data !== 'undefined' && data !== null) {
                        for (var i = 0; i < data.length; i++) {
                            var dataValues = data[i];

                            self.addMarkers($map, $infoWindow, dataValues);
                        }
                    }
                }
            };

            request.onerror = function() {
                // There was a connection error of some sort
            };

            request.send();

        } else if(typeof singleJson !== 'undefined' && singleJson !== null && singleJson !== '') {
            var jsonValues = JSON.parse(singleJson);
            if(typeof jsonValues !== 'undefined' && jsonValues !== null){
                var dataValues = jsonValues[0];
                // check to make sure the map has a center
                if(isNaN($map.center.lat()) && isNaN($map.center.lng())){
                    $map.setCenter({lat: parseFloat(dataValues.Latitude), lng: parseFloat(dataValues.Longitude)});
                }
                self.addMarkers($map, $infoWindow, dataValues);
            }
        }
    }
};


window.addEventListener('load', function () {
    window.ggd.maps.init();
}, false);
