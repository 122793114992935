/*----------------------------------------------------------------------------*\
    Video
    Function for YT video's.
\*----------------------------------------------------------------------------*/

window.ggd.video = {
    init: function () {
        var self = this;
        //self.heroVideo();
        self.getVideos();

        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    },

    initVideo: function (videoId, hasAutoplay, hasControls, $videoPlaceholder) {
        if ($videoPlaceholder !== null) {
            var playerWrap = document.createElement('div');
            $videoPlaceholder.appendChild(playerWrap);
        }

        if (videoId) {
            new window.YT.Player(playerWrap, {
                width: '100%',
                height: 570,
                videoId: videoId,
                playerVars: {
                    'disablekb': 1,
                    'autoplay': hasAutoplay,
                    'controls': hasControls,
                    'loop': 1,
                    'modestbranding': 1,
                    'rel': 0,
                    'showinfo': 0,
                    'playlist': videoId
                }
            });
        }
    },

    /* Init Homepage hero video */
    /*heroVideo: function () {
        var self = this;
        var $videoPlaceholder = document.querySelector('.js--hero__video-insert');
        var $videoHero = document.querySelector('.js--hero-video');*/

    /* Check if video is in hero and if screenwidth is bigger then desk. */
    /*if ($videoHero && Modernizr.mq(enex.config.mq.desk)) {
            var videoId = $videoPlaceholder.getAttribute('data-video-id');
            var hasAutoplay = $videoPlaceholder.getAttribute('data-video-autoplay');
            var hasControls = $videoPlaceholder.getAttribute('data-video-controls');

            self.initVideo(videoId, hasAutoplay, hasControls, $videoPlaceholder);
        }
    },*/

    /*muteVideo: function(player) {
        // Check if video is in hero, then mute it.
        if (player.a.parentNode.className === 'js--hero__video-insert'){
            player.mute();
        }
    },*/

    getVideos: function() {
        var self = this;
        var $videoElements = document.querySelectorAll('.js--video');

        for (var i = 0; i < $videoElements.length; ++i) {
            var $videoElement = $videoElements[i];
            self.loadVideoTrigger($videoElement);
        }
    },

    /* Open video lightbox from button click */
    loadVideoTrigger: function($videoElement) {
        var self = this;
        var $videoTrigger = $videoElement.querySelector('.js--video__trigger');

        var videoId = $videoTrigger.getAttribute('data-video-id');
        var hasAutoplay = $videoTrigger.getAttribute('data-video-autoplay');
        var hasControls = $videoTrigger.getAttribute('data-video-controls');

        $videoTrigger.addEventListener('click', function(){
            $videoElement.classList.add('is-active');
            self.appendVideoBlock(videoId, hasAutoplay, hasControls, this, $videoElement);
        });
    },

    /* Append video to inline video block */
    appendVideoBlock: function (videoId, hasAutoplay, hasControls, $videoTrigger, $videoElement) {
        var self = this;

        var $videoBlock = $videoTrigger.parentNode;
        $videoBlock.classList.add('is-active');

        // Get video wrap of current Video Block.
        var $videoWraps = $videoElement.querySelector('.js--video__wrap');

        // Embed flexembed div and js video insert div.
        $videoWraps.innerHTML = '<div class="flexembed  flexembed--16by9"><div class="js--video-insert  js--video-insert--block"></div></div>';
        var $videoContainer = $videoWraps.querySelector('.js--video-insert--block');

        // Load video in current video block.
        self.initVideo(videoId, hasAutoplay, hasControls, $videoContainer);
        self.closeVideo($videoElement, $videoWraps, $videoBlock);
    },

    closeVideo: function ($videoElement, $videoWraps, $videoBlock) {
        var $closeVideoTrigger = $videoElement.querySelector('.video-block__close');

        if (!$closeVideoTrigger) {
            return false;
        }

        $closeVideoTrigger.addEventListener('click', function(){
            $videoElement.classList.remove('is-active');
            $videoBlock.classList.remove('is-active');

            // Clear video
            $videoWraps.innerHTML = '';
        });
    }
};

window.addEventListener('load', function () {
    window.ggd.video.init();
}, false);
