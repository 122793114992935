/*----------------------------------------------------------------------------*\
    Masthead
    Functions for masthead.
\*----------------------------------------------------------------------------*/

window.ggd.masthead = {
    init: function(){
        var self = this;
        var $mastheads = document.querySelectorAll('.js--masthead');
        self.$content = document.querySelector('.js--masthead__content');
        self.$footer = document.querySelector('.js--masthead__footer');

        for (var i = 0; i < $mastheads.length; ++i) {
            var $masthead = $mastheads[i];
            self.initMasthead($masthead);
        }
    },

    initMasthead: function ($masthead) {
        var self = this;

        var $triggers = $masthead.querySelector('.js--masthead__trigger');
        $triggers.setAttribute('aria-expanded', 'false');

        $triggers.addEventListener('click', function () {
            var $trigger = this;
            var $fold = self.getFold($masthead);
            self.toggleFold($masthead, $trigger, $fold);
        });
    },

    getFold: function ($masthead) {
        var $fold = $masthead.querySelector('.js--masthead__fold');
        return $fold;
    },

    toggleFold: function ($masthead, $trigger, $fold) {
        var self = this;
        var $menuLabel = $trigger.querySelector('.js--hamburger__flag');
        var menuLabelOpen = $menuLabel.getAttribute('data-hamburger-open');
        var menuLabelClose = $menuLabel.getAttribute('data-hamburger-close');

        if ($trigger.classList.contains('is-open')) {
            self.$content.inert = false;
            self.$footer.inert = false;
            $fold.classList.remove('is-animated-in');
            $trigger.classList.remove('is-open');
            $trigger.setAttribute('aria-expanded', 'false');
            $menuLabel.innerHTML = menuLabelOpen;

            document.removeEventListener('keydown', self.closeFoldOnEscape);
            document.removeEventListener('focusin', self.closeFoldOnTab);
            document.removeEventListener('click', self.closeFoldOnTab);

            setTimeout(function () {
                $fold.classList.remove('is-open');
            }, 1000);
        } else {
            self.$content.inert = true;
            self.$footer.inert = true;
            $fold.classList.add('is-open');

            setTimeout(function () {
                $fold.classList.add('is-animated-in');
            }, 1);

            $trigger.classList.add('is-open');
            $trigger.setAttribute('aria-expanded', 'true');
            $menuLabel.innerHTML = menuLabelClose;

            // add eventlistener to close the fold when pressing escape or tabbing outside the fold, remove listeners when fold is closed
            document.addEventListener('keydown', self.closeFoldOnEscape);
            document.addEventListener('focusin', self.closeFoldOnTab);
            document.addEventListener('click', self.closeFoldOnTab);
        }
    },

    closeFoldOnEscape: function (event) {
        if (event.key === 'Escape') {
            var $masthead = document.querySelector('.js--masthead');
            var $trigger = $masthead.querySelector('.js--masthead__trigger');
            var $fold = $masthead.querySelector('.js--masthead__fold');
            
            window.ggd.masthead.toggleFold($masthead, $trigger, $fold);
        }
    },
    closeFoldOnTab: function (event) {
        var $masthead = document.querySelector('.js--masthead');
        var $trigger = $masthead.querySelector('.js--masthead__trigger');
        var $fold = $masthead.querySelector('.js--masthead__fold');

        if (!$fold.contains(event.target) && !$trigger.contains(event.target)) {
            window.ggd.masthead.toggleFold($masthead, $trigger, $fold);
        }
    },
};


window.addEventListener('load', function () {
    window.ggd.masthead.init();
}, false);
