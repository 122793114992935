class Foldout {
    constructor($el) {
        this.$toggle = $el.querySelector('.js--foldout__toggle');
        this.$content = $el.querySelector(`#${this.$toggle.getAttribute('aria-controls')}`);
        this.$focusEl = $el.querySelector('.js--foldout__focus');
        this.init();

        if ($el.classList.contains('js--foldout--search')) {
            window.ggd.search = this;
        }
    }

    init() {
        this.$toggle.addEventListener('click', () => {
            if (this.$toggle.getAttribute('aria-expanded') === 'false') {
                this.open();
            } else {
                this.close();
            }
        });
    }

    open() {
        this.$toggle.setAttribute('aria-expanded', 'true');
        this.$content.classList.add('is-active');

        if (this.$focusEl) {
            this.$focusEl.focus();
        }
    }

    close() {
        this.$toggle.setAttribute('aria-expanded', 'false');
        this.$content.classList.remove('is-active');
        // this.$content.hidden = true;
    }
}

[...document.querySelectorAll('.js--foldout')].forEach(($el) => {
    new Foldout($el);
});
