/*----------------------------------------------------------------------------*\
    Navigation
    Functions for nav elements.
\*----------------------------------------------------------------------------*/

window.ggd.megamenu = {
    activeMenuItem: null,

    init: function(){
        this.initMegaMenu();
    },

    initMegaMenu: function () {
        var self = this;
        var $navTriggers = document.querySelectorAll('.js--nav-primary__trigger');

        for (var i = 0; i < $navTriggers.length; ++i) {
            var $navTrigger = $navTriggers[i];
            self.setupMegaMenu($navTrigger);
        }
    },

    setupMegaMenu: function ($navTrigger) {
        var self = this;

        $navTrigger.addEventListener('click', function(e) {
            // If screen larger then desk make link not clickable
            if (window.matchMedia(window.ggd.config.mq.desk).matches) {
                e.preventDefault();
            }

            var $navMegaMenuWrap = this.nextElementSibling;

            if ($navTrigger === self.activeMenuItem) {
                self.closeMegaMenu(self.activeMenuItem);
                window.ggd.shadow.closeShadow();
            } else {
                if (self.activeMenuItem) {
                    self.closeMegaMenu(self.activeMenuItem);
                }

                self.openMegaMenu(this, $navMegaMenuWrap);
            }

        });
    },

    openMegaMenu: function ($navTrigger, $navMegaMenuWrap) {
        var self = this;

        // store active menu item
        self.activeMenuItem = $navTrigger;
        // Set active class on navtrigger.
        $navTrigger.classList.add('is-open');

        // Set active class on navtrigger.
        $navMegaMenuWrap.classList.add('is-open');
        $navMegaMenuWrap.classList.add('to-open');

        setTimeout(() => {
            $navMegaMenuWrap.classList.remove('to-open');
        }, 600);

        // Open shadow overlay
        window.ggd.shadow.openShadow();

        // Close searchbar if opened.
        if (window.ggd.search) {
            window.ggd.search.close();
        }

        // Set correct Aria rules.
        $navTrigger.setAttribute('aria-expanded', 'true');
        //add eventlististener to close menu on tab

        document.addEventListener('keydown', window.ggd.megamenu.handleTabOutsideMegaMenu, false);
    },

    handleTabOutsideMegaMenu: function (e) {
        const trigger = document.querySelector('.js--nav-primary__trigger.is-open');
        if (!e.target.closest('.js--nav-primary__trigger') && !e.target.closest('.js--nav-primary')) {
            window.ggd.megamenu.closeMegaMenu(trigger);
            window.ggd.shadow.closeShadow();
            document.removeEventListener('keydown', window.ggd.megamenu.handleTabOutsideMegaMenu, false);
        }
    },

    // Close Megamenu and close current active item.
    closeMegaMenu: function ($navTrigger) {
        var self = this;
        self.activeMenuItem = null;
        $navTrigger.classList.remove('is-open');
        $navTrigger.setAttribute('aria-expanded', 'false');
        // window.ggd.shadow.closeShadow();

        // Remove active class frome megamenu container.
        $navTrigger.nextElementSibling.classList.add('from-open');
        setTimeout(() => {
            $navTrigger.nextElementSibling.classList.remove('is-open');
            $navTrigger.nextElementSibling.classList.remove('from-open');
        }, 600);

        // window.ggd.shadow.removeActiveTrigger();
    },

    // Function to close all active menu items. Mainly used for calling from other functions.
    closeAllMenus: function () {
        var $navTriggers = [].slice.call(document.querySelectorAll('.js--nav-primary__trigger')) || [];

        $navTriggers.forEach(function($navTrigger) {
            if ($navTrigger.classList.contains('is-open')) {
                // Remove active class frome megamenu container.
                $navTrigger.nextElementSibling.classList.add('from-open');
                setTimeout(() => {
                    $navTrigger.nextElementSibling.classList.remove('is-open');
                    $navTrigger.nextElementSibling.classList.remove('from-open');
                }, 600);
            }

            // Remove is-open from all navTriggers.
            $navTrigger.classList.remove('is-open');

            // Set correct Aria rules.
            $navTrigger.setAttribute('aria-expanded', 'false');
        });
    }
};


window.addEventListener('load', function () {
    window.ggd.megamenu.init();
}, false);
