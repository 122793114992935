/*----------------------------------------------------------------------------*\
    Shadow.overlay
\*----------------------------------------------------------------------------*/

window.ggd.shadow = {
    init: function(){
        var self = this;
        var $shadowOverlay = document.querySelector('.js--shadow-overlay');
        var $shadowOverlayTriggers = document.querySelectorAll('.js--shadow-overlay__trigger');
        var $shadowOverlayFocuses = document.querySelectorAll('.js--shadow-overlay__focus');

        // Check if shadowOverlay is available.
        if (!$shadowOverlay) {
            return false;
        }

        for (var i = 0; i < $shadowOverlayTriggers.length; ++i) {
            var $shadowOverlayTrigger = $shadowOverlayTriggers[i];
            self.triggerShadow($shadowOverlay, $shadowOverlayTrigger);
            self.clickShadow($shadowOverlay);
        }

        for (var j = 0; j < $shadowOverlayFocuses.length; ++j) {
            var $shadowOverlayFocus = $shadowOverlayFocuses[j];
            self.focusShadow($shadowOverlay, $shadowOverlayFocus);
        }
    },

    openShadow: function () {
        var $shadowOverlay = document.querySelector('.js--shadow-overlay');

        $shadowOverlay.classList.add('is-active');
        $shadowOverlay.classList.add('is-animating-in');

        setTimeout(function() {
            $shadowOverlay.classList.remove('is-animating-in');
        }, 500);
    },

    closeShadow: function () {
        var $shadowOverlay = document.querySelector('.js--shadow-overlay');

        $shadowOverlay.classList.add('is-animating-out');

        setTimeout(function(){
            $shadowOverlay.classList.remove('is-animating-out');
            $shadowOverlay.classList.remove('is-active');
        }, 500);
    },

    // Opens shadow with trigger button click.
    triggerShadow: function ($shadowOverlay, $shadowOverlayTrigger) {
        var self = this;

        $shadowOverlayTrigger.addEventListener('click', function() {
            // Set class is-triggered when clicking trigger.
            $shadowOverlayTrigger.classList.toggle('is-triggered');

            if ($shadowOverlayTrigger.classList.contains('is-triggered')) {
                self.openShadow();
            } else {
                self.closeShadow();
            }
        });
    },

    removeActiveTrigger: function () {
        var $shadowOverlayTriggers = [].slice.call(document.querySelectorAll('.js--shadow-overlay__trigger')) || [];

        $shadowOverlayTriggers.forEach(function($shadowOverlayTrigger) {
            $shadowOverlayTrigger.classList.remove('is-triggered');
        });
    },

    // Opens shadow with focus in inputfield.
    focusShadow: function ($shadowOverlay, $shadowOverlayFocus) {
        var self = this;

        $shadowOverlayFocus.addEventListener('focus', function() {
            self.openShadow();
        });

        $shadowOverlayFocus.addEventListener('blur', function() {
            self.closeShadow();
        });
    },

    // Close shadow when clicking on shadow-overlay. Also close depending menus and triggers.
    clickShadow: function ($shadowOverlay) {
        var self = this;

        $shadowOverlay.addEventListener('click', function(e) {
            e.preventDefault();

            self.closeShadow();

            // Close all megamenus.
            window.ggd.megamenu.closeAllMenus();

            // Close search bar.
            if (window.ggd.search) {
                window.ggd.search.close();
            }
        });
    }
};


window.addEventListener('load', function () {
    window.ggd.shadow.init();
}, false);
