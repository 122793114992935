// libs
import 'picturefill';
import objectFitImages from 'object-fit-images';

// app javascript
import './app/config';
import './app/masthead';
import './app/megamenu';
import './app/cookie';
import './app/accordeon';
import './app/maps';
import './app/alert';
import './app/forms';
import './app/carousel';
import './app/scroll';
import './app/video';
import './app/nav.filter';
import './app/shadow.overlay';
import './app/foldout';
import './app/autocomplete';

objectFitImages();
