/*----------------------------------------------------------------------------*\
    Nav filter
    Filter nav functions, for opening and closing nav.
\*----------------------------------------------------------------------------*/

window.ggd.filter = {
    init: function() {
        var self = this;
        var $filter = document.querySelector('.js--nav-filter');

        if (!$filter) {
            return;
        }

        var $modal = $filter.querySelector('.js--nav-filter__modal'),
            $open = document.querySelector('.js--nav-filter__open'),
            $close = $filter.querySelector('.js--nav-filter__close');

        self.openFilter($filter, $modal, $open, $close);
    },

    openFilter: function ($filter, $modal, $open, $close) {
        $open.addEventListener('click', function(event){
            event.preventDefault();
            $modal.classList.add('is-open');
        });

        $close.addEventListener('click', function(event){
            event.preventDefault();
            $modal.classList.remove('is-open');
        });
    }
};


window.addEventListener('load', function () {
    window.ggd.filter.init();
}, false);
