/*----------------------------------------------------------------------------*\
    Carousel
\*----------------------------------------------------------------------------*/

import Flickity from 'flickity';
import debounce from '../lib/debounce';

/* Enhance Flickity with custom slideStart function as it's not in Flickity's API. */
var startAnimation = Flickity.prototype.startAnimation;
Flickity.prototype.startAnimation = function() {
    if ( this.isAnimating ) {
        return;
    }
    // do previous logic
    startAnimation.apply( this, arguments );
    // trigger event
    this.dispatchEvent('slideStart');
};

window.ggd.carousel = {
    init: function(){
        var self = this;
        var $carousel = [].slice.call(document.querySelectorAll('.js--carousel')) || [];

        if (!$carousel) {
            return false;
        }

        // Loop through all carousel instances.
        $carousel.forEach(function($carousel) {
            self.initCarousel($carousel);
        });
    },

    initCarousel: function ($carousel) {
        var self = this;
        var carouselOptions = JSON.parse($carousel.getAttribute('data-carousel-option'));

        // Init Flickity and get carousel-options from data-attribute
        var flkty = new Flickity($carousel,
            carouselOptions
        );

        // Init Flickity and get carousel-options from data-attribute
        self.customNav($carousel, flkty);
    },

    customNav: function ($carousel, flkty) {
        var self = this;
        var $nav = document.querySelector('.js--carousel__nav');

        // Check if there is a nav, otherwise return false.
        if (!$nav) {
            return false;
        }

        var $navCells = [].slice.call($nav.querySelectorAll('.js--carousel__nav-cell')) || [];

        // Set first nav item to active on pageload.
        self.setActiveNav(flkty, 0, $navCells);

        // When flickity starts scrolling set active nav item.
        flkty.on('slideStart', function() {
            var selectedCell = flkty.selectedIndex;
            self.setActiveNav(flkty, selectedCell, $navCells);
        });

        // Loop through all navigation triggers and get the slide numbers through data-attribute.
        $navCells.forEach(function($navCell) {
            $navCell.addEventListener('click', function() {

                var navCellNumber = $navCell.getAttribute('data-carousel-cell');

                if (navCellNumber) {
                    self.goToSlide(flkty, navCellNumber, $navCells);
                }
            });
        });
    },

    // Set active carousel nav item
    setActiveNav: function(flkty, navCellNumber, $navCells) {
        var self = this;
        var $selectedCell = document.querySelector('.js--carousel__nav-cell[data-carousel-cell="' + navCellNumber + '"]');
        var $navContent = $selectedCell.querySelector('.js--carousel__nav-content');
        var $innerCell = $selectedCell.querySelector('.js--carousel__nav-content__inner');
        var $innerHeight = self.getNavItemHeight($innerCell);

        $navCells.forEach(function($navCellAll) {
            // Remove is-active class from non-active carousel navs
            $navCellAll.classList.remove('is-active');

            // Reset height for all non-active carousel navs.
            var $navContentAll = $navCellAll.querySelector('.js--carousel__nav-content');

            if (window.innerWidth > 767) {
                $navContentAll.style.height = 0 +'px';
            } else {
                $navContentAll.style.height = 'auto';
            }
        });

        $navContent.style.height = $innerHeight+'px';
        $selectedCell.classList.add('is-active');
    },

    // Go to slide when clicking carousel nav item
    goToSlide: function(flkty, navCellNumber, $navCells) {
        var self = this;
        flkty.select(navCellNumber);
        flkty.stopPlayer();

        self.setActiveNav(flkty, navCellNumber, $navCells);
    },

    // Get innerHeight of navigation trigger.
    getNavItemHeight: function($inner){
        var height = 0;
        height = height + $inner.offsetHeight;

        return height;
    }
};


var debounceCarousel = debounce(function() {
    window.ggd.carousel.init();
}, 250);

window.addEventListener('resize', debounceCarousel);

window.addEventListener('load', function () {
    window.ggd.carousel.init();
}, false);
