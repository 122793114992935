/*----------------------------------------------------------------------------*\
    Scroll
    Functions that trigger on scroll events.
\*----------------------------------------------------------------------------*/

import 'waypoints/lib/noframework.waypoints';

window.ggd.scroll = {
    init: function(){
        var self = this;

        self.initSmoothScroll();
        self.initSections();
        self.stickyScroll();
    },

    initSmoothScroll: function() {
        this.selectBoxScroll();
    },

    // Scroll function for selectbox element.
    selectBoxScroll: function() {
        var $selectBox = document.querySelector('.js--smooth-scroll-select');

        if ($selectBox === null) {
            return false;
        }

        $selectBox.addEventListener('change', function(){
            var selectValue = this.options[$selectBox.selectedIndex].value;
            var anchor = document.querySelector(selectValue);

            anchor.scrollIntoView({
                behavior: 'smooth',
            });
        });
    },

    initSections: function() {
        var self = this;

        // Get all sections with a ID.
        var $anchorNav = document.querySelector('.js--anchor-nav');
        var $sections = document.querySelectorAll('section[id]');

        if ($anchorNav === null) {
            return;
        }

        // Loop through all sections on a page.
        for (var i = 0; i < $sections.length; ++i) {
            var $section = $sections[i].id;

            // Create waypoints for all sections
            var element = document.getElementById($section);
            element.setAttribute('number', i);
            new window.Waypoint({
                element: element,
                handler: function handler(direction) {
                    var $item = this.element.id;
                    var number = this.element.getAttribute('number');
                    if (direction === 'up') {
                        if (number > 0) {
                            self.activeNavItem($sections[number - 1].id);
                        }
                    } else {
                        self.activeNavItem($item);
                    }
                },
                offset: '65'
            });
        }
    },

    // Set active class in anchor nav for active section
    activeNavItem: function($item) {
        var $navItems = document.querySelectorAll('.js--anchor-nav__item');

        for (var i = 0; i < $navItems.length; ++i) {
            var $navItem = $navItems[i];
            var navItemHref = $navItem.getAttribute('href');
            var navItemId = navItemHref.split('#');
            navItemId = navItemId[1];

            $navItem.classList.remove('is-active');

            if (navItemId === $item) {
                $navItem.classList.add('is-active');
            }
        }
    },

    // Sticky nav.
    stickyScroll: function() {
        var $el = document.querySelector('.js--sticky-scroll');

        if ($el === null) {
            return false;
        }

        var $elHeight = $el.offsetHeight;

        window.addEventListener('scroll', function() {
            var stickyOffset = $el.getBoundingClientRect().top;

            if(stickyOffset <= 0) {
                $el.classList.add('is-sticky');
                $el.style.paddingTop = $elHeight + 'px';
            } else {
                $el.classList.remove('is-sticky');
                $el.style.paddingTop = '0px';
            }
        });
    },
};


window.addEventListener('load', function () {
    window.ggd.scroll.init();
}, false);
